import Cookies from 'js-cookie';
import { stringify, parse } from 'qs';

// Types
import type { IStringifyOptions } from 'qs';

const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL;

export type ErrorCustom = Omit<ErrorType, 'message'> & {
    errors: Record<string, unknown>;
    status: Response['status'];
    message:
        | Error['message']
        | {
              message?: Error['message'];
              errors?: Record<string, unknown>;
              error?: Error['message'];
          };
};

type ErrorType = {
    config: {
        method: Request['method'];
        url: Request['url'];
    };
    response: {
        data: { message: Error['message']; errors: Record<string, unknown> };
        status: Response['status'];
        statusText: Response['statusText'];
        headers: Headers;
        config: {
            method: Request['method'];
            url: Request['url'];
        };
        request: null;
    };
    message: Error['message'];
};

async function formatError(response: Response, method: Request['method']) {
    const responseData = await getResponseData(response);
    const error: ErrorType = {
        config: {
            method,
            url: response.url
        },
        response: {
            data: responseData,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            config: {
                method,
                url: response.url
            },
            request: null
        },
        message: response.statusText
    };

    const errorCustom = error as ErrorCustom;
    errorCustom.message =
        error?.response?.data?.message ??
        error?.response?.data ??
        'An error occurred while fetching the data.';
    errorCustom.status = error?.response.status;
    errorCustom.errors = error?.response?.data?.errors;
    errorCustom.response = error?.response;
    return errorCustom;
}

async function getResponseData(response: Response) {
    try {
        const headerContentType = response.headers.get('Content-Type');
        if (headerContentType.split('/')[0] === 'image') {
            const responseBlobData = await response.blob();
            return responseBlobData;
        }

        const responseData = await response.json();
        return responseData;
    } catch (e) {
        return null;
    }
}

async function handleErrors(response: Response, method: Request['method']): Promise<unknown> {
    if (!response.ok) {
        throw await formatError(response, method);
    }

    const responseData = await getResponseData(response);
    return { data: responseData };
}

async function fetchInterceptor(...args: Parameters<typeof window.fetch>) {
    const method = args[1].method;
    try {
        const response = await fetch(...args);
        return handleErrors(response, method);
    } catch (error) {
        if (error.name === 'AbortError') {
            return null;
        }
        return handleErrors(error, method);
    }
}

async function get<TData = any>(
    endpoint: string,
    params?: Record<string, any>,
    options?: RequestInit,
    stringifyOptions?: IStringifyOptions
) {
    const [baseEndpoint, paramsEndpoint] = endpoint.split('?');
    const endpointParams = parse(paramsEndpoint);
    const queryString =
        params || endpointParams
            ? '?' +
              stringify(
                  { ...params, ...endpointParams },
                  {
                      encode: true,
                      arrayFormat: 'indices',
                      ...stringifyOptions
                  }
              )
            : '';

    return (await fetchInterceptor(`${BASE_API_URL}/api${baseEndpoint}${queryString}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        },
        credentials: 'include',
        ...options
    })) as Promise<TData>;
}

async function post<TData = any>(
    endpoint: string,
    data?: Record<string, unknown> | FormData,
    options?: RequestInit
) {
    const headers: Record<string, string> = {
        Accept: 'application/json',
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
    };

    let requestBody: BodyInit | undefined = undefined;

    if (data instanceof FormData) {
        // Si le corps est de type FormData
        requestBody = data;
    } else {
        // Si le corps est de type JSON
        headers['Content-Type'] = 'application/json';
        requestBody = JSON.stringify(data);
    }

    return (await fetchInterceptor(`${BASE_API_URL}/api${endpoint}`, {
        method: 'POST',
        headers,
        body: requestBody,
        credentials: 'include',
        ...options
    })) as Promise<TData>;
}

async function put<TData = any>(
    endpoint: string,
    data?: Record<string, unknown>,
    options?: RequestInit
) {
    return (await fetchInterceptor(`${BASE_API_URL}/api${endpoint}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        },
        body: JSON.stringify(data),
        credentials: 'include',
        ...options
    })) as Promise<TData>;
}

async function deleteApi<TData = any>(
    endpoint: string,
    options?: { data?: Record<string, unknown> } & RequestInit
) {
    return (await fetchInterceptor(`${BASE_API_URL}/api${endpoint}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        },
        credentials: 'include',
        body: JSON.stringify(options?.data),
        ...options
    })) as Promise<TData>;
}

async function patch<TData = any>(
    endpoint: string,
    data: Record<string, unknown>,
    options?: RequestInit
) {
    return (await fetchInterceptor(`${BASE_API_URL}/api${endpoint}`, {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
        },
        body: JSON.stringify(data),
        credentials: 'include',
        ...options
    })) as Promise<TData>;
}

export { get, post, put, deleteApi, patch };
