export const INITIAL = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/create/account';
export const SIGN_UP_TEAM_MEMBER = '/create/addtm';
export const FORGOT_PASSWORD = '/forgot-password';
export const COLLABORATE_LOGIN = '/collaborate-area/login/[token]';
export const COLLABORATE_AREA = '/collaborate-area/[token]';
export const RESET_PASSWORD = '/reset-password/[token]';
export const GIVEAWAY_PICKER = '/giveaway-picker';
export const GIVEAWAY_PICKER_RESULTS = '/giveaway-picker/results';
