/**
 * Permanent acl features
 */
export const aclFeatures = {
    api: {
        base: {
            read: 'feature.api.base.read'
        },
        zapier: {
            read: 'feature.api.zapier.read'
        }
    },
    report: {
        white_label: {
            read: 'feature.report.white_label.read',
            update: 'feature.report.white_label.update'
        }
    },
    content: {
        campaign: {
            read: 'feature.content.campaign.read'
        }
    },
    analytic: {
        promoted_post: { read: 'feature.analytic.promoted_post.read' }
    },
    publishing: {
        first_comment: { create: 'feature.publishing.first_comment.create' },
        ig_collaboration_post: { create: 'feature.publishing.ig_collaboration_post.create' },
        external_collaboration: { read: 'feature.publishing.external_collaboration.read' }
    },
    listening: {
        competitor: {
            create: 'feature.listening.competitor.create',
            delete: 'feature.listening.competitor.delete'
        },
        hashtag: {
            read: 'feature.listening.hashtag.read',
            create: 'feature.listening.hashtag.create',
            delete: 'feature.listening.hashtag.delete'
        },
        feed: {
            read: 'feature.listening.feed.read',
            create: 'feature.listening.feed.create',
            update: 'feature.listening.feed.update',
            delete: 'feature.listening.feed.delete'
        }
    },
    social_profile: {
        base: {
            create: 'feature.social_profile.base.create',
            delete: 'feature.social_profile.base.delete'
        },
        youtube: {
            create: 'feature.social_profile.youtube.create'
        }
    },
    group: {
        base: {
            read: 'feature.group.base.read'
        }
    },
    account: {
        base: {
            delete: 'feature.account.base.delete'
        },
        billing: {
            read: 'feature.account.billing.read',
            update: 'feature.account.billing.update'
        },
        invitation: {
            create: 'feature.account.invitation.create',
            update: 'feature.account.invitation.update',
            delete: 'feature.account.invitation.delete'
        },
        team_member: {
            update: 'feature.account.team_member.update',
            delete: 'feature.account.team_member.delete'
        },
        data_access: {
            read: 'feature.account.data_access.read'
        }
    }
};

/**
 * Temporary acl features
 */

export const aclTemporaryFeatures = {
    publishing: {
        new_funnel: { read: 'temporary.publishing.new_funnel.read' }
    },
    account: {
        new_billing: { read: 'temporary.account.new_billing.read' }
    },
    front: {
        tree_structure: { read: 'temporary.front.tree_structure.read' }
    }
};
