export const HOME = '/home';
export const DASHBOARD = '/dashboard/overview';
export const DASHBOARD_VIEW = '/dashboard/view/[id]';
export const DASHBOARD_CUSTOMIZATION = '/dashboard/customization/[id]';
export const DASHBOARD_GENERATE_CUSTOM_REPORT = '/dashboard/report/[id]';
export const ANALYZE_OVERVIEW = '/analytics/overview';
export const ANALYZE_ENGAGEMENT = '/analytics/engagement';
export const ANALYZE_COMMUNITY = '/analytics/community';
export const ANALYZE_REACH = '/analytics/reach';
export const ANALYZE_REACH_IMPRESSIONS = '/analytics/reach_impressions';
export const ANALYZE_PROFILE_ACTIVITY = '/analytics/activity';
export const ANALYZE_STORIES = '/analytics/stories';
export const ANALYZE_CONTENT = '/analytics/content';
export const ANALYZE_VIDEOS = '/analytics/fbvideos';
export const ANALYZE_PERFORMANCE = '/analytics/performance';
export const ANALYZE_IMPRESSIONS = '/analytics/impressions';
export const ANALYZE_MENTIONS = '/analytics/mentions';
export const ANALYZE_REELS = '/analytics/reels';
export const ANALYZE_INDUSTRY_BENCHMARK = '/listening/industry-benchmark';
export const REPORTS = '/reports';
export const REPORTS_ACCOUNTS = '/reports/create/account';
export const REPORTS_DASHBOARDS = '/reports/create/dashboard';
export const REPORTS_COMPETITORS = '/reports/create/competitor';
export const REPORTS_HASHTAGS = '/reports/create/hashtag';
export const REPORTS_EDIT = '/reports/edit/[id]';
export const CONTENT_POSTS = '/content/posts';
export const CONTENT_ALBUMS = '/content/campaigns';
export const CONTENT_ALBUMS_COMPARATIVE_TABLE = '/content/campaigns/comparative-table';
export const CONTENT_ALBUM_POSTS = '/content/campaigns/[albumId]';
export const CONTENT_ALBUM_ANALYTICS = '/content/campaigns/[albumId]/analytics';
export const CONTENT_ALBUM_STATS = '/content/campaigns/[albumId]/stats';
export const SCHEDULER = '/publishing/scheduler';
export const MEDIA_LIBRARY = '/publishing/media-library';
export const SCHEDULER_COLLABORATION = '/publishing/collaboration';
export const SCHEDULER_COLLABORATION_SETTINGS = '/publishing/collaboration/sharing-settings';
export const SCHEDULER_COLLABORATION_NOTIFICATIONS = '/publishing/collaboration/notifications';
export const SCHEDULER_FEED_VIEW = '/publishing/scheduler/feed-preview';
export const ACCOUNT = '/settings/account';
export const DATA_ACCESS = '/settings/data-access';
export const PLATFORM_OPTIONS = '/settings/platform';
export const THEMES = '/settings/themes';
export const SECURITY = '/settings/security';
export const INTEGRATIONS = '/settings/integrations';
export const SUBSCRIPTION_INVOICES = '/settings/subscription-invoices';
export const USERS_ROLES = '/users-roles';
export const SOCIAL_PROFILES = '/social-profiles';
export const MEDIA = '/media/[id]';
export const MANAGE_COMMENTS_TRACKER = '/conversations/comments';
export const MANAGE_MENTIONS_TRACKER = '/conversations/mentions';
export const MANAGE_DIRECT_MESSAGES = '/conversations/direct-messages';
export const SUBSCRIPTION = '/user/billing';
export const LISTENING_HASHTAG = '/listening/hashtags/[id]';
export const LISTENING_HASHTAGS = '/listening/hashtags';
export const LISTENING_COMPETITORS = '/listening/competitors';
export const LISTENING_COMPETITOR = '/listening/competitors/[id]';
export const LISTENING_FEEDS = '/listening/feeds';
export const LISTENING_FEED = '/listening/feeds/[id]';
export const CAPTIONS_LISTS = '/publishing/saved-captions-hashtags';
export const CHECKOUT = '/checkout';
export const DOWNGRADE_FUNNEL = '/downgrade-funnel';
export const DOWNGRADE_FUNNEL_BLOCKED = '/downgrade-funnel/contact';
export const DISCOVER = '/discover';
export const NO_ACTIVE_SOCIAL_PROFILE = '/no-active-sp';
export const REFERRAL_PROGRAM = '/referral-program';
export const NOTIFICATIONS = '/notifications';
export const GROUPS = '/groups';
export const INSPIRATION = '/inspiration';
export const PDF_EXPORT = '/pdf/export';

export const ERROR_COLLABORATION_LINK = '/errors/collaboration-link';

//homepage Options
export const HOMEPAGE = HOME;
export const GROUP_HOMEPAGE = ANALYZE_OVERVIEW;
//Sign up steps
export const SIGN_UP = {
    USER_EMAIL_VERIFICATION: '/setup/email-verification',
    USER_CREATED: '/setup/account',
    USER_SET_UP: '/create/addsp',
    SOCIAL_PROFILES_CONNECTED: '/create/addspsuccess'
} as const;
export const SIGN_UP_URL_PATHNAME = {
    USER_CREATED: '/signup/user-infos',
    USER_SET_UP: '/signup/connect-social-profiles',
    SOCIAL_PROFILES_CONNECTED: '/signup/successful-connection'
};
// OAUTH NETWORKS
export const OAUTH_NETWORKS = {
    instagram: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/facebook',
    facebook: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/facebook',
    twitter: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/twitter',
    linkedin: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/linkedin',
    tiktok_business: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/tiktok_business',
    tiktok_business_ads: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/tiktok_business_ads',
    youtube: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/youtube',
    pinterest: process.env.NEXT_PUBLIC_BASE_API_URL + '/oauth/pinterest'
};

// OAUTH SIGNUP
export const OAUTH_SIGNUP = {
    google: process.env.NEXT_PUBLIC_BASE_API_URL + '/login/google'
} as const;
