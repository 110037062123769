// Utils
import useAcl from '@/utils/hooks/user/useAcl';
import useUser from '@/utils/hooks/swr/useUser';

// Lib
import { aclFeatures } from '@/lib/acl/aclFeatures';

export function useUserGroupRestriction() {
    const { hasAcl } = useAcl();
    const { user } = useUser();

    const userHasReachGroupLimit = () => {
        /**
         * Check if user has a quota on groups and if the remaining is less than the purchased
         */
        if (typeof user?.quotas?.groups?.remaining === 'number') {
            return user.quotas.groups.remaining === 0;
        }

        return false;
    };

    return {
        hasGroupPermission: hasAcl(aclFeatures.group.base.read),
        hasReachGroupLimit: userHasReachGroupLimit()
    };
}
